module.exports = {
  title: `yeim log`,
  description: `Welcome to yeim blog. `,
  author: `Yeongbin Im`,
  introduction: `지식과 경험의 공유를 통해서, 제가 만들 소프트웨어를 통해서 다른 사람들에게 좋은 영향력을 주고, 이로 인해 저 또한 성장하는 개발자가 되고 싶습니다.`,
  siteUrl: `https://blog.yeim.dev`, // Your blog site url
  social: {
    twitter: ``, // Your Twitter account
    github: `yeongbinim`, // Your GitHub account
    medium: ``, // Your Medium account
    facebook: ``, // Your Facebook account
    linkedin: `yeongbin-im-a26a54234?_l=ko_KR`, // Your LinkedIn account
    instagram: `run_bean0`, // Your Instagram account
  },
  icon: `content/assets/felog.png`, // Add your favicon
  keywords: [`blog`],
  comment: {
    disqusShortName: '', // Your disqus-short-name. check disqus.com.
    utterances: 'yeongbinim/yeim-log', // Your repository for archive comment
  },
  configs: {
    countOfInitialPost: 12, // Config your initial count of post
  },
  sponsor: {
    buyMeACoffeeId: '',
  },
  share: {
    facebookAppId: '', // Add facebookAppId for using facebook share feature v3.2
  },
  ga: '0', // Add your google analytics tranking ID
  ad: '', // Add your google adsense publisherId `ca-pub-xxxxxxxxxx`

}
